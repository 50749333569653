"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileBindings = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_i18next_1 = require("react-i18next");
var react_router_1 = require("react-router");
var react_query_1 = require("@tanstack/react-query");
var components_1 = require("../../components");
var services_1 = require("../../services");
var ProfileBindings = function () {
    var t = (0, react_i18next_1.useTranslation)().t;
    var location = (0, react_router_1.useLocation)();
    var history = (0, react_router_1.useHistory)();
    var queryClient = (0, react_query_1.useQueryClient)();
    var storeId = (0, react_router_1.useParams)().storeId;
    var _a = __read((0, react_1.useState)(false), 2), isRemoveModalOpened = _a[0], setIsRemoveModalOpened = _a[1];
    var _b = __read((0, react_1.useState)(''), 2), bindingRemoveKey = _b[0], setBindingRemoveKey = _b[1];
    var _c = services_1.UserService.useRemoveBinding(), removeBinding = _c.removeBinding, isRemovingBinding = _c.isRemovingBinding;
    var _d = services_1.UserService.useGetUser(), user = _d.data, isUserLoading = _d.isLoading, isError = _d.isError;
    var _e = services_1.UserService.useGetBindings(!!user), cards = _e.cards, isBindingsLoading = _e.isBindingsLoading;
    var onOpenRemoveModal = function (key) {
        setBindingRemoveKey(key);
        setIsRemoveModalOpened(true);
    };
    var onCloseRemoveModal = function () {
        if (!isRemovingBinding) {
            setIsRemoveModalOpened(false);
        }
    };
    var onRemoveBinding = function () {
        removeBinding({ key: bindingRemoveKey }, {
            onSuccess: function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4, queryClient.invalidateQueries(['bindings-list'])];
                        case 1:
                            _a.sent();
                            setIsRemoveModalOpened(false);
                            return [2];
                    }
                });
            }); },
        });
    };
    if (isUserLoading || isBindingsLoading) {
        return (0, jsx_runtime_1.jsx)(components_1.FullPageLoader, {});
    }
    if (isError || !user) {
        history.push({ pathname: "/store/".concat(storeId), search: location.search });
    }
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "profile-page" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "profile-page-container" }, { children: [(0, jsx_runtime_1.jsx)(components_1.HeaderNavigation, { isProfilePage: true }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "profile-page-menu" }, { children: [cards.length === 0 && ((0, jsx_runtime_1.jsx)("div", __assign({ className: "profile-page-bindings-empty" }, { children: (0, jsx_runtime_1.jsx)("h2", __assign({ className: "profile-page-bindings-empty-title" }, { children: t('Your card list is empty') })) }))), cards.length > 0 &&
                                cards.map(function (_a, index) {
                                    var card_mask = _a.card_mask, uuid = _a.uuid;
                                    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "profile-page-menu-item" }, { children: [(0, jsx_runtime_1.jsx)(components_1.Icon, { name: "card-pay" }), (0, jsx_runtime_1.jsxs)("span", { children: ["**** ", card_mask] }), (0, jsx_runtime_1.jsx)("span", __assign({ className: "profile-page-menu-item-remove" }, { children: (0, jsx_runtime_1.jsx)(components_1.Icon, { name: "trash", size: 20, onClick: function () { return onOpenRemoveModal(uuid); } }) }))] }), index));
                                })] }))] })), (0, jsx_runtime_1.jsx)(components_1.SwipeUpModal, __assign({ isOpen: isRemoveModalOpened, onClose: onCloseRemoveModal }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "profile-page-remove-modal" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "profile-page-remove-modal-header" }, { children: [(0, jsx_runtime_1.jsx)(components_1.Icon, { name: "chevron-down", size: 24, onClick: onCloseRemoveModal }), (0, jsx_runtime_1.jsx)("h3", __assign({ className: "profile-page-remove-modal-title" }, { children: t('Are you sure to remove your card?') }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "profile-page-remove-modal-buttons" }, { children: [(0, jsx_runtime_1.jsx)(components_1.Button, __assign({ onClick: onCloseRemoveModal, disabled: isRemovingBinding }, { children: t('Cancel') })), (0, jsx_runtime_1.jsx)(components_1.Button, __assign({ color: "danger", onClick: onRemoveBinding, disabled: isRemovingBinding }, { children: t('Remove') }))] }))] })) }))] })));
};
exports.ProfileBindings = ProfileBindings;
