"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileEdit = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var react_router_1 = require("react-router");
var formik_1 = require("formik");
var components_1 = require("../../components");
var services_1 = require("../../services");
var utils_1 = require("../../utils");
var ProfileEdit = function () {
    var _a, _b;
    var t = (0, react_i18next_1.useTranslation)().t;
    var location = (0, react_router_1.useLocation)();
    var history = (0, react_router_1.useHistory)();
    var storeId = (0, react_router_1.useParams)().storeId;
    var _c = services_1.UserService.useGetUser(), user = _c.data, isUserLoading = _c.isLoading, isError = _c.isError;
    var _d = services_1.UserService.useUpdateUser(), updateUser = _d.updateUser, isUpdatingUser = _d.isUpdatingUser;
    var _e = (0, formik_1.useFormik)({
        initialValues: { name: (_a = user === null || user === void 0 ? void 0 : user.name) !== null && _a !== void 0 ? _a : '', email: (_b = user === null || user === void 0 ? void 0 : user.email) !== null && _b !== void 0 ? _b : '' },
        validationSchema: utils_1.userValidation,
        validateOnChange: false,
        enableReinitialize: true,
        validateOnBlur: false,
        onSubmit: function (_a) {
            var name = _a.name, email = _a.email;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    updateUser({ name: name, email: email });
                    return [2];
                });
            });
        },
    }), handleSubmit = _e.handleSubmit, handleChange = _e.handleChange, values = _e.values, errors = _e.errors;
    if (isUserLoading) {
        return (0, jsx_runtime_1.jsx)(components_1.FullPageLoader, {});
    }
    if (isError || !user) {
        history.push({ pathname: "/store/".concat(storeId), search: location.search });
    }
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "profile-page" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "profile-page-container" }, { children: [(0, jsx_runtime_1.jsx)(components_1.HeaderNavigation, { isProfilePage: true }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "profile-page-edit" }, { children: (0, jsx_runtime_1.jsxs)("form", __assign({ className: "profile-page-edit-form", onSubmit: handleSubmit }, { children: [(0, jsx_runtime_1.jsx)(components_1.FormField, __assign({ error: errors.name, touched: true }, { children: (0, jsx_runtime_1.jsx)(components_1.Input, { type: "text", name: "name", value: values.name, placeholder: t('Name'), onChange: handleChange }) })), (0, jsx_runtime_1.jsx)(components_1.FormField, __assign({ error: errors.email, touched: true }, { children: (0, jsx_runtime_1.jsx)(components_1.Input, { type: "text", name: "email", value: values.email, placeholder: t('Email Address'), onChange: handleChange }) })), (0, jsx_runtime_1.jsx)(components_1.Button, __assign({ type: "submit", disabled: isUpdatingUser }, { children: t('Save') }))] })) }))] })) })));
};
exports.ProfileEdit = ProfileEdit;
