"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeaderNavigation = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_router_dom_1 = require("react-router-dom");
var hooks_1 = require("../../hooks");
var utils_1 = require("../../utils");
var Icons_1 = require("../Icons");
var IconCycle_1 = require("../Icons/IconCycle");
var Languages_1 = require("../Languages");
require("./index.scss");
var HeaderNavigation = function (_a) {
    var _b = _a.showBackButton, showBackButton = _b === void 0 ? true : _b, navigationTitle = _a.navigationTitle, isProfilePage = _a.isProfilePage;
    var chainLogo = (0, hooks_1.useGetConfigs)().chainLogo;
    var _c = (0, hooks_1.useNavigate)(), onGoBack = _c.onGoBack, onGoToCatalogPage = _c.onGoToCatalogPage, onGoToProfilePage = _c.onGoToProfilePage;
    var orderType = (0, hooks_1.useSelector)(function (state) { return state.checkout.orderType; });
    var isDinein = orderType === utils_1.ORDER_TYPES.dinein;
    var onTapBackButton = function () {
        if (isProfilePage) {
            onGoBack();
        }
        else {
            onGoToCatalogPage();
        }
    };
    return ((0, jsx_runtime_1.jsxs)("nav", __assign({ className: "header-navigation" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "header-navigation-left" }, { children: showBackButton ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ onClick: onTapBackButton }, { children: (0, jsx_runtime_1.jsx)(IconCycle_1.IconCycle, { children: (0, jsx_runtime_1.jsx)(Icons_1.Icon, { name: "back" }) }) })), navigationTitle && (0, jsx_runtime_1.jsx)("h2", __assign({ className: "catalog-nav-title" }, { children: navigationTitle }))] })) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: isDinein ? ((0, jsx_runtime_1.jsx)("img", { src: chainLogo, className: "header-navigation-logo", alt: "" })) : ((0, jsx_runtime_1.jsx)(react_router_dom_1.Link, __assign({ to: "/store" }, { children: (0, jsx_runtime_1.jsx)("img", { src: chainLogo, className: "header-navigation-logo", alt: "" }) }))) })) })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "header-navigation-right" }, { children: [(0, jsx_runtime_1.jsx)(Languages_1.Languages, {}), !isProfilePage && ((0, jsx_runtime_1.jsx)("div", __assign({ onClick: onGoToProfilePage }, { children: (0, jsx_runtime_1.jsx)(IconCycle_1.IconCycle, { children: (0, jsx_runtime_1.jsx)(Icons_1.Icon, { name: "person" }) }) })))] }))] })));
};
exports.HeaderNavigation = HeaderNavigation;
