"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Confirm = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_activity_1 = require("react-activity");
var react_i18next_1 = require("react-i18next");
var react_router_1 = require("react-router");
var services_1 = require("../../services");
var PaymentSuccess_1 = require("./PaymentSuccess");
var PaymentFail_1 = require("./PaymentFail");
require("./index.scss");
var Confirm = function () {
    var t = (0, react_i18next_1.useTranslation)().t;
    var paymentId = (0, react_router_1.useParams)().paymentId;
    var history = (0, react_router_1.useHistory)();
    var payment = services_1.PaymentService.useGetPayment(paymentId).payment;
    var order = payment.order || {};
    var tableId = order.table_id || order.table_number;
    var isSuccess = payment.status === 'accepted';
    var isFail = payment.status === 'canceled';
    var onGoBack = function () {
        history.push({
            pathname: "/store/".concat(order.restaurant_id),
            search: "?type=".concat(order.type).concat(tableId ? "&table=".concat(tableId) : ''),
        });
    };
    if (isSuccess) {
        return (0, jsx_runtime_1.jsx)(PaymentSuccess_1.PaymentSuccess, { onGoBack: onGoBack, payment: payment });
    }
    if (isFail) {
        return (0, jsx_runtime_1.jsx)(PaymentFail_1.PaymentFail, { onGoBack: onGoBack });
    }
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "table-confirm-centered" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "table-confirm-container" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "table-confirm-title" }, { children: t('Confirming your payment') })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "page-loader" }, { children: (0, jsx_runtime_1.jsx)(react_activity_1.Spinner, {}) }))] })) })));
};
exports.Confirm = Confirm;
