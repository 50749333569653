"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthService = void 0;
var react_query_1 = require("@tanstack/react-query");
var react_router_1 = require("react-router");
var ua_parser_js_1 = require("ua-parser-js");
var react_redux_1 = require("react-redux");
var react_toastify_1 = require("react-toastify");
var hooks_1 = require("../../hooks");
var store_1 = require("../../store");
var utils_1 = require("../../utils");
var httpClient_1 = require("../httpClient");
var AuthService = (function () {
    function AuthService() {
    }
    AuthService.useLogout = function () {
        var dispatch = (0, react_redux_1.useDispatch)();
        var queryClient = (0, react_query_1.useQueryClient)();
        var onGoToCatalogPage = (0, hooks_1.useNavigate)().onGoToCatalogPage;
        var clearUserBindingsInfo = function () {
            dispatch((0, store_1.changeSaveCard)(false));
            dispatch((0, store_1.changeAmeriaCardBindingUuid)(''));
        };
        var _a = (0, react_query_1.useMutation)(['logout'], function () { return httpClient_1.httpClient.post('/logout'); }, {
            onSuccess: function () {
                localStorage.removeItem('user:token');
                queryClient.removeQueries(['user']);
                clearUserBindingsInfo();
                onGoToCatalogPage();
            },
            onError: function (error) {
                var _a, _b;
                if ((_a = error.data) === null || _a === void 0 ? void 0 : _a.message) {
                    react_toastify_1.toast.error((_b = error.data) === null || _b === void 0 ? void 0 : _b.message);
                }
            },
        }), logout = _a.mutate, isLogoutLoading = _a.isLoading;
        return {
            logout: logout,
            isLogoutLoading: isLogoutLoading,
        };
    };
    AuthService.useAuth = function () {
        var state = (0, react_router_1.useLocation)().state;
        var dispatch = (0, react_redux_1.useDispatch)();
        var isIos = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)/i);
        var isAndroid = navigator.userAgent.match(/(android)/i);
        var userAgent = new ua_parser_js_1.UAParser().getResult();
        var device_model = userAgent.device.vendor || 'unknown device';
        var platform_v = "".concat(userAgent.browser.name, " ").concat(userAgent.browser.version);
        var device_id = (0, utils_1.stringToHash)([device_model, platform_v, userAgent.ua, userAgent.os.name || ''].join(''));
        var app_v = VERSION || '';
        var platform = '';
        if (isIos) {
            platform = 'ios';
        }
        else if (isAndroid) {
            platform = 'android';
        }
        else {
            platform = 'desktop';
        }
        return (0, react_query_1.useMutation)(['user-auth', utils_1.chainId], function (_a) {
            var phone = _a.phone, name = _a.name, email = _a.email, code = _a.code;
            return httpClient_1.httpClient
                .post('/auth', {
                chain_id: utils_1.chainId,
                phone: phone,
                name: name,
                email: email,
                code: code,
                platform: platform,
                app_v: app_v,
                device_id: device_id,
                device_model: device_model,
                platform_v: platform_v,
            })
                .then(function (res) { return res.data; });
        }, {
            onSuccess: function (data) {
                localStorage.setItem('user:token', data.token);
                if (state === null || state === void 0 ? void 0 : state.saveCard) {
                    dispatch((0, store_1.changeSaveCard)(true));
                }
                window.location.reload();
            },
            onError: function (error) {
                var _a, _b;
                if ((_a = error.data) === null || _a === void 0 ? void 0 : _a.message) {
                    react_toastify_1.toast.error((_b = error.data) === null || _b === void 0 ? void 0 : _b.message);
                }
            },
        });
    };
    AuthService.useSendAuthCode = function () {
        return (0, react_query_1.useMutation)(['send-auth-code', utils_1.chainId], function (_a) {
            var chain_id = _a.chain_id, phone = _a.phone;
            return httpClient_1.httpClient
                .post('/code', {
                chain_id: chain_id,
                phone: phone,
            })
                .then(function (res) { return res.data; });
        }, {
            onError: function (error) {
                var _a, _b;
                if ((_a = error.data) === null || _a === void 0 ? void 0 : _a.message) {
                    react_toastify_1.toast.error((_b = error.data) === null || _b === void 0 ? void 0 : _b.message);
                }
            },
        });
    };
    return AuthService;
}());
exports.AuthService = AuthService;
__exportStar(require("./types"), exports);
