"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckoutProducts = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_redux_1 = require("react-redux");
var react_i18next_1 = require("react-i18next");
var components_1 = require("../../../components");
var hooks_1 = require("../../../hooks");
var utils_1 = require("../../../utils");
var store_1 = require("../../../store");
require("./index.scss");
var CheckoutProducts = function () {
    var t = (0, react_i18next_1.useTranslation)().t;
    var checkoutProducts = (0, hooks_1.useSelector)(function (state) { return state.checkout.products; });
    var currency = (0, hooks_1.useGetConfigs)().currency;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "checkout-page-subtitle" }, { children: t('Basket') })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "checkout-page-products" }, { children: checkoutProducts.map(function (product) { return ((0, jsx_runtime_1.jsx)(ProductItem, { product: product, currency: currency }, product.basketProductIndex)); }) }))] }));
};
exports.CheckoutProducts = CheckoutProducts;
var ProductItem = function (_a) {
    var product = _a.product, currency = _a.currency;
    var t = (0, react_i18next_1.useTranslation)().t;
    var dispatch = (0, react_redux_1.useDispatch)();
    var onGoToProductPage = (0, hooks_1.useNavigate)().onGoToProductPage;
    var id = product.id, photo = product.photo, title = product.title, quantity = product.quantity, basketProductIndex = product.basketProductIndex;
    var productPrice = (0, utils_1.calculateProductSubtotal)(product);
    var checkedModifiers = [];
    product.modifier_groups.forEach(function (modifierGroup) {
        modifierGroup.modifier_items.forEach(function (modifier) {
            if (modifier.checked) {
                checkedModifiers.push(modifier.name);
            }
        });
    });
    var onCustomize = function () {
        onGoToProductPage(id, product, 'checkout');
    };
    var onRemoveProductFromBasket = function () {
        dispatch((0, store_1.removeProductFromBasket)(basketProductIndex));
    };
    var increase = function () { return dispatch((0, store_1.increaseBasketProductQuantity)(basketProductIndex)); };
    var decrease = function () {
        if (quantity <= 1) {
            onRemoveProductFromBasket();
            return;
        }
        dispatch((0, store_1.decreaseBasketProductQuantity)(basketProductIndex));
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "checkout-page-products-item" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "checkout-page-products-item-left" }, { children: (0, jsx_runtime_1.jsx)("img", { className: "checkout-page-products-item-image", src: photo.thumb, alt: title }) })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "checkout-page-products-item-right" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "checkout-page-products-item-details" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "checkout-page-products-item-details-name" }, { children: title })), (0, jsx_runtime_1.jsx)(components_1.MultipleSelectPrimary, { className: "checkout-page-products-item-details-multiply", isMinusDisabled: false, quantity: quantity, onClickMinus: decrease, onClickPlus: increase })] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "checkout-page-products-item-modifiers" }, { children: checkedModifiers.map(function (modifierName) {
                            return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "checkout-page-products-item-modifiers-modifier" }, { children: modifierName }), modifierName));
                        }) })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "checkout-page-products-item-footer" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "checkout-page-products-item-buttons" }, { children: [(0, jsx_runtime_1.jsxs)("button", __assign({ className: "checkout-page-products-item-button", onClick: onCustomize }, { children: [(0, jsx_runtime_1.jsx)(components_1.Icon, { name: "pencil", size: 12 }), (0, jsx_runtime_1.jsx)("span", { children: t('Customise') })] })), (0, jsx_runtime_1.jsxs)("button", __assign({ className: "checkout-page-products-item-button", onClick: onRemoveProductFromBasket }, { children: [(0, jsx_runtime_1.jsx)(components_1.Icon, { name: "trash", size: 12 }), (0, jsx_runtime_1.jsx)("span", { children: t('Delete') })] }))] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "checkout-page-products-item-price" }, { children: (0, utils_1.getPrice)({ price: productPrice, currency: currency }) }))] }))] }))] })));
};
