"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IPaymentProvider = void 0;
var IPaymentProvider;
(function (IPaymentProvider) {
    IPaymentProvider["Stripe"] = "stripe";
    IPaymentProvider["StripeUAE"] = "stripe_uae";
    IPaymentProvider["StripeUSA"] = "stripe_usa";
    IPaymentProvider["Yookassa"] = "yookassa";
    IPaymentProvider["Ameriabank"] = "ameriabank";
    IPaymentProvider["Tinkoff"] = "tinkoff";
    IPaymentProvider["Idram"] = "idram";
})(IPaymentProvider = exports.IPaymentProvider || (exports.IPaymentProvider = {}));
