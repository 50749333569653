"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppWrapper = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_i18next_1 = require("react-i18next");
require("./index.scss");
var AppWrapper = function (_a) {
    var children = _a.children;
    var i18n = (0, react_i18next_1.useTranslation)().i18n;
    (0, react_1.useLayoutEffect)(function () {
        var isArabicLanguage = i18n.language === 'ar';
        if (isArabicLanguage) {
            document.body.classList.add('arabic-language');
        }
    }, []);
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "app-wrapper" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "app-wrapper-content" }, { children: children })) })));
};
exports.AppWrapper = AppWrapper;
