"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChainService = void 0;
var react_query_1 = require("@tanstack/react-query");
var httpClient_1 = require("../httpClient");
var ChainService = (function () {
    function ChainService() {
    }
    ChainService.useGetChain = function () {
        var _a = (0, react_query_1.useQuery)(['chain'], function () { return (0, httpClient_1.getData)("/chains/get"); }), _b = _a.data, data = _b === void 0 ? {} : _b, isChainLoading = _a.isInitialLoading;
        var banners = data.banners || [];
        var stores = data.restaurants || [];
        return {
            isChainLoading: isChainLoading,
            banners: banners,
            stores: stores,
            logoLink: data.logo_link,
            splashLink: data.splash_link,
            contactDetails: data.contact_details,
            legalDetails: data.legal_details,
            termsLink: data.terms_link,
            privacyLink: data.privacy_link
        };
    };
    return ChainService;
}());
exports.ChainService = ChainService;
__exportStar(require("./types"), exports);
